import { ACPVariant } from 'apps/acp/variants/acp-variant';

const colors = {
  neutral: {
    color0: '#ffffff',
    color50: '#F7F8F8',
    color100: '#EFF0F0',
    color200: '#E0E1E1',
    color300: '#D0D2D1',
    color400: '#C1C3C2',
    color500: '#97999B',
    color600: '#8E908F',
    color700: '#333F48',
    color800: '#333F48',
    color900: '#232424'
  },
  default: {
    color50: '#DADEE8',
    color100: '#B5BDD1',
    color200: '#919DBB',
    color300: '#6C7CA4',
    color400: '#475B8D',
    color500: '#223A76',
    color600: '#1B2E5E',
    color700: '#142347',
    color800: '#0E172F',
    color900: '#070C18'
  },
  accent: {
    color50: '#E9EAED',
    color100: '#D2D4DA',
    color200: '#A6AAB4',
    color300: '#797F8F',
    color400: '#4D5569',
    color500: '#202A44',
    color600: '#1A2236',
    color700: '#131929',
    color800: '#0D111B',
    color900: '#06080E'
  },
  positive: {
    color50: '#DADEE8',
    color100: '#B5BDD1',
    color200: '#919DBB',
    color300: '#6C7CA4',
    color400: '#475B8D',
    color500: '#223A76',
    color600: '#1B2E5E',
    color700: '#142347',
    color800: '#0E172F',
    color900: '#070C18'
  },
  negative: {
    color50: '#FCD9DE',
    color100: '#F8B3BD',
    color200: '#F58D9C',
    color300: '#F1677B',
    color400: '#EE415A',
    color500: '#E91D2D',
    color600: '#BB162E',
    color700: '#8C1022',
    color800: '#5E0B17',
    color900: '#2F050B'
  },
  special: {
    color50: '#FCD9DE',
    color100: '#F8B3BD',
    color200: '#F58D9C',
    color300: '#F1677B',
    color400: '#EE415A',
    color500: '#E91D2D',
    color600: '#BB162E',
    color700: '#8C1022',
    color800: '#5E0B17',
    color900: '#2F050B'
  }
};

const variant: ACPVariant = {
  id: 'www.deepbluedebit.com',
  productTitle: 'DEEPBLUE Debit Account',
  productType: 'consumer_dda',
  accessDomain: {
    subdomain: 'www',
    domain: 'deepbluedebit',
    topLevelDomain: 'com'
  },
  colors,
  themeConfiguration: {
    acpActivationAppBar: {
      defaultBackgroundColor: colors.neutral.color0
    },
    acpRisingTideLayout: {
      appBarDefaultBackgroundColor: colors.neutral.color0
    }
  },
  legacyAcpTheme: 'deepblue-debit',
  legacyLegosTheme: 'deepblue-debit',
  legacyAcpBrandName: 'deepbluedebit',
  status_bar_background_color: colors.neutral.color0,
  status_bar_foreground_color: 'dark',
  contactInfo: {
    name: 'DEEPBLUE Debit',
    contact_address_line1: 'PO Box 2136',
    contact_address_line2: 'Austin, TX 78768-2136',
    contact_address_line3: '',
    customer_support_email: 'customerservice@deepbluedebit.com',
    fax_formatted: '(866) 358-0526',
    phone_formatted: '1-833-954-1605',
    phone: '18339541605',
    phone_international: '737-220-8956',
    balance_check_phone: '18339541605',
    balance_check_phone_formatted: '1-833-954-1605',
    pre_funded_checks_phone: '18778147683',
    pre_funded_checks_phone_formatted: '1-877-814-7683'
  },
  configuration: {
    apiKeys: {
      appsFlyerDevKey: '',
      appsFlyerBannerKey: 'fc0827e2-3b63-45c7-98ef-15525f9a93ae',
      'google-tag-manager-container-id': 'GTM-NQXKPD',
      'google-tag-manager-container-id-mobile': 'GTM-MWBTT5M',
      paypalFraudNetPayerIdProd: '',
      paypalFraudNetPayerIdTest: ''
    },
    featureToggles: {
      activationSetExternalId: false,
      appsFlyerEnabled: false,
      combinedTransactionsMasterAndSub: false,
      'embedded-activation': true,
      embeddedPayNearMe: false,
      hasNotificationCenter: false,
      mobilePBREnabled: true,
      preOnboardingEnabled: false,
      showAccountDisplayNickname: false,
      showAcquisitionLink: false,
      showATMFriendlyCards: true,
      showDisplayNameForSubAccount: false,
      showEmailInformationButton: true,
      showFeePlanPage: false,
      showHowToEarnRewardPointLink: true,
      showRegisterLink: false,
      showScanCardRegistration: false,
      showMarketingSiteLink: false,
      showFreeAtmFinderLink: false,
      showWUTransfers: false,
      showReloadLocationLink: false
    },
    misc: {
      distributor: 'libertydeepblue',
      layoutComponentOverride: false,
      layoutAppBarInverted: true,
      loader: 'generic',
      payNearMeButtonTextColor: '#FFFFFF',
      payNearMeActionBarBackgroundColor: colors.default.color500,
      payNearMeTitleTextColor: '#FFFFFF',
      payNearMeColorAccent: colors.accent.color500,
      marketingSiteUrl: 'https://www.deepbluedebit.com',
      marketingSiteLinkText: 'Deepbluedebit.com',
      locationSearchMax: '25',
      iOSAppId: '1580591069'
    },
    content: {
      signUpContent: "Don't have a card? Sign up today.",
      copyrightStatement:
        'Ouro Global, Inc. All rights reserved worldwide. Netspend is the federally registered U.S. service mark of Ouro Global, Inc. All other trademarks and service marks belong to their owners.'
    },
    disclaimers: {
      overdraftDisclaimer: `The Debit Card Overdraft Service is an optional service made available to eligible DEEPBLUE™ Debit customers by Republic Bank & Trust Company, Member FDIC. Once you enroll and meet the eligibility requirements, you will be charged $20.00 for each transaction that overdraws your account by more than $10.00, up to a maximum of five (5) fees per calendar month. To avoid that fee, you have twenty-four (24) hours from the time of the first transaction that creates the overdraft to bring your Account back to a zero ($0.00) or a positive balance. Transactions subject to an overdraft fee are one-time PIN and signature based purchase transactions and ATM withdrawals. Other transactions including ACH Debit transactions are not eligible for coverage. Any negative balance must be repaid within thirty (30) days. <strong>Whether we authorize an overdraft is discretionary, and we reserve the right not to pay.</strong> For example, we typically will not pay overdrafts if you fail to meet the eligibility requirements for this service or you attempt too many transactions or transactions that create too large of an overdraft. Call us at 1-833-954-1605 or log in to your Online Account Center for additional terms and conditions that apply, including initial and ongoing eligibility requirements for the service. <strong>This service is expensive, so we encourage you to research alternatives before enrolling.</strong> Once enrolled, you may opt-out at any time; however, you are responsible to repay any overdrawn amounts on your account even if you opt-out of the service.`
    },
    sections: [
      'dashboard-gpr',
      'dashboard-lite',
      'dashboard-handoff',
      'layout',
      'manage-gpr',
      'move-money',
      'overdraft',
      'locations',
      'direct-deposit',
      'direct-deposit-mobile-panel',
      'statements',
      'contact-us',
      'restrictions-readonly',
      'feeplan',
      'western-union',
      'disclosure-dictionary',
      'check-deposit',
      'checks',
      'quickbooks-oauth',
      'points',
      'post-handoff-to-activate-register',
      'upgrade',
      'offers',
      'offers-intro',
      'tours',
      'post-install-multi-screen-acquisition-flow',
      'echecks',
      'secure-inbox',
      'benefit-center',
      'atm-finder',
      'savings',
      'pay-bills',
      'premier-plan',
      'activation',
      'savings-transfer',
      'anytime-alerts',
      'anytime-alerts-upgrade',
      'change-username',
      'change-password',
      'mobile-check-load',
      'feedback',
      'rent-track',
      'direct-deposit-form',
      'dynamic-faq',
      'billpay',
      'card',
      'refer-a-friend',
      'close-account',
      'bank-transfers',
      'handoff',
      'card-use-settings',
      'secure-upload',
      'digital-wallet',
      'me-to-me-transfer',
      'money-gram',
      'survey',
      'spend-money',
      'fraud-detection',
      'spending-tracker',
      'connected-banks',
      'interstitials'
    ]
  },
  benefits: [
    {
      name: 'purchase-assurance',
      title: 'Purchase Assurance<sup>1</sup>',
      image: 'purchase-assurance-tube',
      benefit_type: 'purchase_benefit'
    },
    {
      name: 'id-theft-alerts',
      title: 'ID Theft Alerts<sup>2</sup>',
      image: 'id-theft-alerts',
      benefit_type: 'purchase_benefit'
    },
    {
      name: 'cell-phone-purchase-assurance',
      title: 'Cell Phone Protection Coverage<sup>3</sup>',
      image: 'cell-protection-service-coverage-signal',
      benefit_type: 'purchase_benefit'
    }
  ]
};

export default variant;
